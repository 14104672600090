<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="批量修改物流信息"
    :visible.sync="show"
    width="1100px"
  >
    <div class="content">
      <p class="tipsWrap">
        当前选中{{
          multipleSelection.length
        }}笔订单，代发货商品如下，请设置选中发货车辆
      </p>
      <h2>采购商品列表</h2>
      <el-table
        border
        v-loading="loading"
        :data="tableData"
        max-height="300"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="barNo" label="条形码">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseUnit"
          label="采购单位"
        >
        </el-table-column>
        <el-table-column prop="inPrice" label="采购单价(元)"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="采购数量"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="金额小计(元)">
          <template slot-scope="{ row }">
            <span>{{ Number(row.payPrice || 0).toFixed(2) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <h2>赠送商品列表</h2>
      <el-table
        border
        v-loading="loading"
        :data="tableData2"
        max-height="300"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="barNo" label="条形码">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseUnit"
          label="采购单位"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="presentNum"
          label="赠送数量"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="activityName"
          label="活动名称"
        ></el-table-column>
      </el-table> -->
      <h2>发货车辆设置</h2>
      <el-form
        style="margin-top: 20px; width: 300px"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <template v-if="form.type === 1">
          <el-form-item label="车牌号" prop="carNo">
            <el-select
              v-model="form.carNo"
              filterable
              placeholder="请选择车牌号"
            >
              <el-option
                v-for="item in carList"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
                <!-- <span>{{ item.label }}</span>
                <span style="margin-left: 6px; color: #8492a6; font-size: 13px"
                  >({{ item.name }})</span
                > -->
              </el-option>
              <div slot="empty" class="emptyWrap">
                <p>未找到车辆信息？</p>
                <div>
                  <el-button type="text" @click="addVisible = true"
                    >点击添加</el-button
                  >
                </div>
              </div>
            </el-select>
          </el-form-item>

          <el-form-item label="联系电话" prop="phone">
            <el-input
              type="number"
              v-model="form.phone"
              placeholder="请输入联系电话"
            />
          </el-form-item>

          <el-form-item label="联系人" prop="consignee">
            <el-input v-model="form.consignee" placeholder="请输入联系人" />
          </el-form-item>
        </template>

        <template v-if="form.type === 2">
          <el-form-item label="快递公司" prop="logisticsCompany">
            <el-input
              v-model.trim="form.logisticsCompany"
              placeholder="请输入快递公司"
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入联系电话" />
          </el-form-item>
        </template>

        <template v-if="form.type === 3">
          <el-form-item label="快递公司" prop="logisticsCompany">
            <el-input
              v-model.trim="form.logisticsCompany"
              placeholder="请输入快递公司"
            />
          </el-form-item>

          <el-form-item label="快递单号" prop="logisticsNo">
            <el-input v-model="form.logisticsNo" placeholder="请输入快递单号" />
          </el-form-item>
        </template>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >确定</el-button
      >
    </span>

    <AddModal
      v-if="addVisible"
      @getData="getCarList"
      :visible.sync="addVisible"
      :groupList="groupList"
      :current-item="currentItem"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import AddModal from './AddModal.vue'

export default {
  props: ['multipleSelection'],
  mixins: [dialogCommonParams],
  data() {
    const _this = this

    return {
      rules: {
        type: [{ required: true, trigger: 'blur', message: '请选择配送方式' }],
        logisticsCompany: [
          { required: true, trigger: 'blur', message: '请输入快递公司' }
        ],
        logisticsNo: [
          { required: true, trigger: 'blur', message: '请输入快递单号' }
        ],
        carNo: [{ required: true, trigger: 'blur', message: '请输入车牌号' }],
        consignee: [
          { required: true, trigger: 'blur', message: '请输入联系人' }
        ],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入联系电话'))

              if (!_this.$utils.isPhone(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ]
      },
      form: {
        type: 1,
        purchaseNo: '',
        logisticsCompany: '',
        logisticsNo: ''
      },
      addVisible: false,
      tableData: [],
      tableData2: [],
      groupList: [],
      carList: [],
      typeList: [
        {
          label: '快递配送',
          value: 3
        },
        {
          label: '整车物流',
          value: 1
        },
        {
          label: '零单物流',
          value: 2
        }
      ],
      file: null,
      btnLoading: false,
      loading: false,
      params: {}
    }
  },
  created() {
    this.getCarGroupList()
    this.getCarList()
    this.getBatchData()
  },
  components: {
    AddModal
  },
  methods: {
    async getBatchData() {
      this.loading = true
      try {
        const res = await this.$api.orderList.getBatchData(
          this.multipleSelection
        )
        this.tableData = (res.details || []).map(item => {
          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getCarGroupList() {
      try {
        const res = await this.$api.carManage.getCarGroupList()

        let group = res.group || []

        group.push({
          groupName: '未分组',
          id: ''
        })

        this.groupList = res.group.map(item => ({
          label: item.groupName,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getCarList() {
      try {
        const res = await this.$api.carManage.getCarAllList()

        this.carList = res.vehicles.map(item => ({
          label: item.licensePlate,
          name: item.vehicleName,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },

    typeChange() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          const sendData = this.form

          sendData.purchaseNos = this.multipleSelection

          try {
            await this.$api.orderList.updateLogistics(sendData)
            this.$message.success('保存成功')
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}

.tipsWrap {
  margin-bottom: 16px;
}

.emptyWrap {
  padding: 10px;
  text-align: center;
}
h2 {
  margin: 20px 10px 10px;
  font-size: 15px;
}
</style>
