<template>
  <page>
    <template slot="headerInfo">
      <el-button
        v-auth="'supply-purchase-detail:batchUpdate'"
        @click="batchEditLogistics"
        :disabled="!multipleSelection.length"
        type="primary"
      >
        批量修改物流信息
      </el-button>
      <el-button
        v-auth="'supplierList:downloadShipped'"
        :disabled="!tableData.length"
        @click="exportData"
        type="primary"
      >
        导出订单信息
      </el-button>
    </template>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="订单编号" prop="purchaseNo">
          <el-input v-model="params.purchaseNo" placeholder="订单编号" />
        </el-form-item>
        <el-form-item label="下单时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="订单来源" prop="orderSource">
          <el-select v-model="params.orderSource" clearable>
            <el-option
              v-for="item in orderSourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="门店分组" prop="groupManageId">
          <el-select v-model="params.groupManageId" clearable>
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商户名称" prop="storesName">
          <el-input
            v-model="params.storesName"
            clearable
            placeholder="商户名称"
          />
        </el-form-item>

        <el-form-item label="发货车辆" prop="licensePlate">
          <el-select v-model="params.licensePlate" clearable>
            <el-option
              v-for="item in carList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="省" prop="provinceId">
          <el-select v-model="params.provinceId" clearable @change="getCity">
            <el-option
              v-for="item in provinceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" prop="cityId">
          <el-select v-model="params.cityId" clearable @change="getRegion">
            <el-option
              v-for="item in cityList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区/县" prop="regionId">
          <el-select v-model="params.regionId" clearable>
            <el-option
              v-for="item in regionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          min-width="100"
          prop="purchaseNo"
          label="订单编号"
        />

        <el-table-column
          class-name="tableGoodsListWrap"
          min-width="150"
          label="商品清单"
        >
          <template slot-scope="{ row }">
            <div class="addressDetail">
              <p
                :title="`${item.goodsName}*${item.goodsNum}`"
                v-for="(item, index) in row.isAll
                  ? row.goodsAllList
                  : row.goodsPartList"
                :key="index"
              >
                <span class="maxText"
                  >{{ item.goodsName }}*{{ item.goodsNum }}</span
                >
              </p>

              <el-button
                v-if="row.goodsAllList.length > 3"
                @click="row.isAll = !row.isAll"
                type="text"
                :icon="!row.isAll ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                >{{ !row.isAll ? '展开' : '收起' }}</el-button
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          width="100"
          prop="payPrice"
          label="订单金额(元)"
        />

        <el-table-column
          show-overflow-tooltip
          min-width="120"
          prop="orderSourceStr"
          label="订单来源"
        />

        <el-table-column
          show-overflow-tooltip
          min-width="120"
          prop="merchantName"
          label="下单商户"
        />

        <el-table-column show-overflow-tooltip min-width="200" label="收货信息">
          <template slot-scope="{ row }">
            <div class="addressDetail">
              <p>
                <span class="label">收货人:</span>
                <span class="maxText">{{ row.cargoReceiver }}</span>
              </p>
              <p>
                <span class="label">联系方式:</span>
                <span class="maxText">{{ row.phone || '-' }}</span>
              </p>
              <p>
                <span class="label">收货地址:</span>
                <span class="maxText">{{ row.storeAddress || '-' }}</span>
              </p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="createTimeStr"
          label="下单时间"
        />

        <el-table-column
          show-overflow-tooltip=""
          min-width="200"
          label="发货信息"
        >
          <template slot-scope="{ row }">
            <div v-if="row.purchaseLogistics" class="addressDetail">
              <p>
                <span class="label">发货方式:</span>
                <span class="maxText">{{ row.sendWay }}</span>
              </p>

              <template v-if="row.type == 1">
                <p>
                  <span class="label">车牌号:</span>
                  <span class="maxText">{{ row.carNo }}</span>
                </p>
                <p>
                  <span class="label">联系电话:</span>
                  <span class="maxText">{{ row.linkWay || '-' }}</span>
                </p>
                <p>
                  <span class="label">联系人:</span>
                  <span class="maxText">{{ row.linkMan }}</span>
                </p>
              </template>

              <template v-if="row.type == 2">
                <p>
                  <span class="label">快递公司:</span>
                  <span class="maxText">{{ row.logisticsCompany }}</span>
                </p>
                <p>
                  <span class="label">联系电话:</span>
                  <span class="maxText">{{ row.linkWay || '-' }}</span>
                </p>
              </template>

              <template v-if="row.type == 3">
                <p>
                  <span class="label">快递公司:</span>
                  <span class="maxText">{{ row.logisticsCompany }}</span>
                </p>
                <p>
                  <span class="label">快递单号:</span>
                  <span class="maxText">{{ row.logisticsNo || '-' }}</span>
                </p>
              </template>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="arrivalTimeStr"
          label="发货时间"
        />
        <el-table-column width="150" label="操作" fixed="right">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'supply-purchase-detail:shipped'"
              @click="detailHandler(row)"
              type="text"
              size="mini"
            >
              详情
            </el-button>

            <el-button
              v-auth="'supply-purchase-detail:updateLog'"
              v-if="row.status == 3"
              @click="sendHandler(row)"
              type="text"
              size="mini"
            >
              修改物流
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />

    <EditLogisticsModal
      v-if="editLogisticsVisible"
      :visible.sync="editLogisticsVisible"
      :currentItem="currentItem"
      :multipleSelection="multipleSelection"
      @getData="getData(true)"
    />

    <BatchEditLogisticsModal
      v-if="batchEditLogisticsVisible"
      :visible.sync="batchEditLogisticsVisible"
      :currentItem="currentItem"
      :multipleSelection="multipleSelection"
      @getData="getData(true)"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal.vue'
import BatchEditLogisticsModal from './components/BatchEditLogisticsModal.vue'
import EditLogisticsModal from './components/EditLogisticsModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      currentItem: null,
      loading: false,
      isDistribution: false,
      visible: false,
      addVisible: false,
      detailVisible: false,
      amountVisible: false,
      batchEditLogisticsVisible: false,
      editLogisticsVisible: false,
      tableData: [],
      carList: [],
      provinceList: [],
      cityList: [],
      regionList: [],
      groupList: [],
      orderSourceList: [
        {
          label: '中间商',
          value: '0'
        },
        {
          label: '门店',
          value: '1'
        }
      ],
      orderStatusList: [
        {
          label: '已发货',
          value: true
        },
        {
          label: '待发货',
          value: false
        }
      ],
      payStatusList: [
        {
          label: '已付款',
          value: '1'
        },
        {
          label: '未付款',
          value: '2'
        }
      ],
      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        date: []
      },
      totalCount: 0
    }
  },
  components: {
    EditLogisticsModal,
    DetailModal,
    BatchEditLogisticsModal
  },
  created() {
    if (this.$route.query.orderId) {
      this.params.purchaseNo = this.$route.query.orderId
    }
    this.getProvince()
    this.getGroupList()
    this.getCarList()
  },
  methods: {
    batchEditLogistics() {
      this.batchEditLogisticsVisible = true
    },
    async getProvince() {
      try {
        const res = await this.$api.common.getProvince()

        this.provinceList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getCity(val) {
      if (!val) return
      try {
        const res = await this.$api.common.getCity(val)

        this.cityList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getRegion(val) {
      if (!val) return
      try {
        const res = await this.$api.common.getRegion(val)

        this.regionList = (res.list || []).map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getGroupList() {
      try {
        const res = await this.$api.common.getGroupAllList()
        this.groupList = (res.groupManages || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async getCarList() {
      try {
        const res = await this.$api.carManage.getCarAllList()

        this.carList = res.vehicles.map(item => ({
          label: item.licensePlate,
          value: item.id
        }))
      } catch (e) {
        console.log(e)
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        for (let i in params) {
          if (params[i] === '') params[i] = undefined
        }

        if (params.date && params.date.length) {
          params.startTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.endTime = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.startTime = undefined
          params.endTime = undefined
        }

        const { date, ...sendData } = params

        sendData.sendOutBool = true

        const res = await this.$api.orderList.getOrderList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          item.amount = this.$utils.amount(item.amount)

          item.createTimeStr = this.$utils.dateFormat(
            item.createTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          item.arrivalTimeStr = this.$utils.dateFormat(
            item.arrivalTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          item.payTimeStr = this.$utils.dateFormat(
            item.payTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          const goodsList = item.detailVos.map(_ => {
            return {
              goodsName: _.goods && _.goods.goodsName,
              goodsNum: _.goodsNum
            }
          })

          if (item.purchaseLogistics) {
            const obj = item.purchaseLogistics

            item.type = obj.type

            if (obj.type == 1) {
              item.sendWay = '整车物流'
              item.carNo = obj.carNo
              item.linkWay = obj.phone
              item.linkMan = obj.consignee
            } else if (obj.type == 2) {
              item.sendWay = '零单物流'
              item.logisticsCompany = obj.logisticsCompany
              item.linkWay = obj.phone
            } else {
              item.sendWay = '快递配送'
              item.logisticsCompany = obj.logisticsCompany
              item.logisticsNo = obj.logisticsNo
            }
          } else {
            item.purchaseLogistics = null
          }

          item.isAll = false
          item.goodsAllList = [...goodsList]
          item.goodsPartList = [...goodsList.slice(0, 3)]
          item.orderSourceStr = item.orderSource == 0 ? '中间商' : '门店'

          item.storeAddress =
            (item.provinceName || '') +
            (item.cityName || '') +
            (item.regionName || '') +
            (item.address || '')

          return item
        })

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    sendHandler(row) {
      this.currentItem = row
      this.editLogisticsVisible = true
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },

    async exportData() {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.startTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endTime = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.startTime = undefined
          params.endTime = undefined
        }

        await this.$api.orderList.exportProject({
          status: 3,
          startTime: params.startTime,
          endTime: params.endTime,
          purchaseNos: this.multipleSelection.length
            ? this.multipleSelection
            : undefined,
          licensePlate: params.licensePlate || undefined,
          groupManageId: params.groupManageId || undefined,
          sendOutBool: params.sendOutBool || undefined
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>
